import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useState } from "react";
//import ax_before from './assets/ax_before.jpg';

function Slider3() {
  const first = {
    id: 1,
    imageUrl: "https://presentation.milanlaser.com/images/bikini5_after.jpg"
  };
  const second = {
    id: 2,
    imageUrl: "https://presentation.milanlaser.com/images/bikini5_before.jpg"
  };
  const third = {
    id: 3,
    imageUrl: "https://presentation.milanlaser.com/images/legs_dark_after.jpg"
  };
  const fourth = {
    id: 4,
    imageUrl: "https://presentation.milanlaser.com/images/legs_dark_before.jpg"
  };
  const fifth = {
    id: 5,
    imageUrl: "https://presentation.milanlaser.com/images/legs_after.jpg"
  };
  const sixth = {
    id: 6,
    imageUrl: "https://presentation.milanlaser.com/images/legs_before.jpg"
  };
  const seventh = {
    id: 7,
    imageUrl: "https://presentation.milanlaser.com/images/legs3_after.jpg"
  };
  const eighth = {
    id: 8,
    imageUrl: "https://presentation.milanlaser.com/images/legs3_before.jpg"
  };

  const [enlarge, setEnlarge] = useState(false);
  const [enlargeBefore, setEnlargeBefore] = useState("");
  const [enlargeAfter, setEnlargeAfter] = useState("");

  const enlargeMode = (before, after) => {
    console.log(before, after);
    setEnlargeBefore(before);
    setEnlargeAfter(after);
    setEnlarge(true);
  };
  return (
    <Grid
      container
      style={{ marginTop: 25, marginBottom: 25, justifyContent: "center" }}
    >
      {enlarge ? (
        <Grid item md={6}>
          <ReactBeforeSliderComponent
            firstImage={enlargeBefore}
            width="100%"
            secondImage={enlargeAfter}
          />
          <center>
            <Button
              className="areaButton"
              variant="contained"
              onClick={() => {
                setEnlarge(false);
              }}
            >
              Close
            </Button>
          </center>
        </Grid>
      ) : (
        <>
          <Grid item md={6}>
            <div style={{ marginLeft: "35%", marginRight: "15%" }}>
              <ReactBeforeSliderComponent
                firstImage={first}
                secondImage={second}
              />
              <center>
                <Button
                  className="areaButton"
                  variant="contained"
                  onClick={() => {
                    enlargeMode(first, second);
                  }}
                >
                  Enlarge
                </Button>
              </center>
            </div>
          </Grid>
          <Grid item md={6}>
            <div style={{ marginLeft: "15%", marginRight: "35%" }}>
              <ReactBeforeSliderComponent
                firstImage={third}
                secondImage={fourth}
              />
              <center>
                <Button
                  className="areaButton"
                  variant="contained"
                  onClick={() => {
                    enlargeMode(third, fourth);
                  }}
                >
                  Enlarge
                </Button>
              </center>
            </div>
          </Grid>
          <Grid item md={6}>
            <div
              style={{
                marginLeft: "35%",
                marginRight: "15%",
                marginTop: "10%"
              }}
            >
              <ReactBeforeSliderComponent
                firstImage={fifth}
                secondImage={sixth}
              />
              <center>
                <Button
                  className="areaButton"
                  variant="contained"
                  onClick={() => {
                    enlargeMode(fifth, sixth);
                  }}
                >
                  Enlarge
                </Button>
              </center>
            </div>
          </Grid>
          <Grid item md={6}>
            <div
              style={{
                marginLeft: "15%",
                marginRight: "35%",
                marginTop: "10%"
              }}
            >
              <ReactBeforeSliderComponent
                firstImage={seventh}
                secondImage={eighth}
              />
              <center>
                <Button
                  className="areaButton"
                  variant="contained"
                  onClick={() => {
                    enlargeMode(seventh, eighth);
                  }}
                >
                  Enlarge
                </Button>
              </center>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Slider3;
