import { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import keynote1 from "./assets/keynote1.jpeg";
import keynote2 from "./assets/keynote2.jpeg";
import keynote3 from "./assets/keynote3.jpeg";
import keynote4 from "./assets/keynote4.jpeg";
import keynote5 from "./assets/keynote5.jpeg";
import keynote6 from "./assets/keynote6.jpeg";
import keynote7 from "./assets/keynote7.jpeg";
import keynote8 from "./assets/keynote8.jpeg";
import keynote9 from "./assets/keynote9.jpeg";
import keynote10 from "./assets/keynote10.jpeg";
//import keynote11 from './assets/keynote11.jpeg';
import keynote12 from "./assets/keynote12.jpeg";
import keynote13 from "./assets/keynote13.jpeg";
import keynote14 from "./assets/keynote14.jpeg";
import keynote15 from "./assets/keynote15.jpeg";
import keynote16 from "./assets/keynote16.jpeg";
import keynote17 from "./assets/keynote17.jpeg";
import keynote18 from "./assets/keynote18.jpeg";
import keynote19 from "./assets/keynote19.jpeg";
import { FaHome } from "react-icons/fa";
import { FullScreen } from "@chiragrupani/fullscreen-react";
import Slider from "./Slider.js";
import Slider2 from "./Slider2.js";
import Slider3 from "./Slider3.js";
import Slider4 from "./Slider4.js";
import Slider5 from "./Slider5.js";
import Slider6 from "./Slider6.js";
import Slider7 from "./Slider7.js";
import Video from "./Video.js";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineFullscreen,
  AiOutlineFullscreenExit
} from "react-icons/ai";
const handleDragStart = e => e.preventDefault();
const items = [
  <img
    src={keynote1}
    className="KeynoteImage"
    alt="key"
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote2}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote3}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote4}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote5}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote6}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote7}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote8}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote9}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote10}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <></>,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote12}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote13}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <></>,
  <></>,
  <></>,
  <></>,
  <></>,
  <></>,
  <></>,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote14}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote15}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote16}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote17}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote18}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />,
  <img
    alt="key"
    className="KeynoteImage"
    src={keynote19}
    width="100%"
    onDragStart={handleDragStart}
    height="100%"
  />
];

function Carousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);
  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  /*  const fullScreenOn = () => {
        useFullScreenHandle().enter;
        setFullScreenMode(true);
    }
    const fullScreenOff = () => {
        handle.exit;
         setFullScreenMode(false);
    }*/
  const slide = () => {
    switch (activeIndex) {
      case 10:
        return <Video />;
      case 13:
        return <Slider />;
      case 14:
        return <Slider2 />;
      case 15:
        return <Slider3 />;
      case 16:
        return <Slider4 />;
      case 17:
        return <Slider5 />;
      case 18:
        return <Slider6 />;
      case 19:
        return <Slider7 />;
      default:
        return (
          <AliceCarousel
            mouseTracking
            disableDotsControls
            disableButtonsControls
            items={items}
            activeIndex={activeIndex}
            onSlideChanged={syncActiveIndex}
          />
        );
    }
  };

  return (
    <FullScreen isFullScreen={fullScreenMode}>
      {slide()}
      <div className="arrowLeft" onClick={slidePrev}>
        <AiOutlineLeft style={{ padding: 50 }} />
      </div>

      <div className="arrowRight" onClick={slideNext}>
        <AiOutlineRight style={{ padding: 50 }} />
      </div>
      <div
        className="homeIcon"
        onClick={() => {
          window.location.reload();
        }}
      >
        <FaHome style={{ padding: 20 }} />
      </div>
      {fullScreenMode ? (
        <div
          className="fullScreenIconExit"
          onClick={() => setFullScreenMode(false)}
        >
          <AiOutlineFullscreenExit style={{ padding: 20 }} />
        </div>
      ) : (
        <div className="fullScreenIcon" onClick={() => setFullScreenMode(true)}>
          <AiOutlineFullscreen style={{ padding: 20 }} />
        </div>
      )}
    </FullScreen>
  );
}

export default Carousel;
