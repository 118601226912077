//import keynote11 from './assets/keynote11.jpeg';
import ReactPlayer from "react-player";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
function Video() {
  const font = "'Open Sans', sans-serif";

  return (
    <>
      <Typography
        variant="h3"
        style={{ marginTop: "3%", fontWeight: 800, fontFamily: font }}
      >
        Laser Hair Removal in action
      </Typography>
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Grid item md={12}>
          <center>
            <ReactPlayer
              className="video"
              style={{ alignSelf: "center" }}
              controls
              url="https://presentation.milanlaser.com/images/media1.mp4"
            />
          </center>
        </Grid>
      </Grid>
    </>
  );
}

export default Video;
