import "./App.css";
import { useState } from "react";
import Carousel from "./Carousel.js";
import milan_logo from "./assets/milan_logo.png";
import Button from "@material-ui/core/Button";
import { BiLinkExternal } from "react-icons/bi";

function App() {
  const [showSalesPres, setShowSalesPres] = useState(false);
  return (
    <div className="App">
      {showSalesPres ? (
        <Carousel />
      ) : (
        <>
          <center>
            <img alt="milanLogo" src={milan_logo} width={200} />
          </center>
          {/*<Typography variant="h4">Milan Laser Virtual Consultation</Typography>*/}
          <div style={{ marginTop: 30, flexDirection: "column" }}>
            <Button
              className="areaButton"
              variant="contained"
              onClick={() => {
                setShowSalesPres(true);
              }}
            >
              Presentation
            </Button>
            <br />
            <a
              style={{ textDecoration: "none" }}
              href="https://quote.milanlaser.com/"
            >
              <Button
                className="areaButton"
                variant="contained"
                endIcon={BiLinkExternal}
              >
                Quote Calculator
              </Button>
            </a>
            <br />
            <a
              style={{ textDecoration: "none" }}
              href="https://storerank.milanlaser.com/"
            >
              <Button
                className="areaButton"
                variant="contained"
                endIcon={BiLinkExternal}
              >
                Store Rank Calculator
              </Button>
            </a>
            <br />
            <a
              style={{ textDecoration: "none" }}
              href="https://upsell.milanlaser.com/"
            >
              <Button
                className="areaButton"
                variant="contained"
                endIcon={BiLinkExternal}
              >
                Commission Calculator
              </Button>
            </a>
            <br />
            <a
              style={{ textDecoration: "none" }}
              href="https://survey.milanlaser.com/"
            >
              <Button
                className="areaButton"
                variant="contained"
                endIcon={BiLinkExternal}
              >
                Client Satisfaction Survey
              </Button>
            </a>
            <br />
            <a
              style={{ textDecoration: "none" }}
              href="https://milanlaser.com/bodymap"
            >
              <Button
                className="areaButton"
                variant="contained"
                endIcon={BiLinkExternal}
              >
                Body Map
              </Button>
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
